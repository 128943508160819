import axios from "axios";
import React, { useState } from "react";
import "./Contact.sass";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const validateForm = () => {
    let formErrors = {};

    if (!name) formErrors.name = "Imię i nazwisko są wymagane";
    if (!email) {
      formErrors.email = "Adres e-mail jest wymagany";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Adres e-mail jest nieprawidłowy";
    }
    if (!message) formErrors.message = "Wiadomość jest wymagana";

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = "service_1uh0xrg";
    const templateId = "template_uls73lu";
    const publicKey = "uaIZdXnOKP3LoJKM9";

    // Create an object with EmailJS service ID, template ID, Public Key, and Template params
    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        from_name: name,
        from_email: email,
        to_name: "Ada",
        message: message,
      },
    };

    // Send the email using EmailJS
    try {
      const res = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        data
      );
      console.log(res.data);
      setName("");
      setEmail("");
      setMessage("");
      setErrors({});
      setSubmitSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="contact-container">
        <div className="contact-heading">Kontakt</div>
        <div className="contact-text">
          Jeśli masz jakiekolwiek pytania, napisz do mnie na e-mail:
          <b>Adriannabak6@gmail.com</b>, lub użyj poniższego formularza
        </div>

        <form onSubmit={handleSubmit} className="emailForm">
          <input
            type="text"
            placeholder="Imię i Nazwisko"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={errors.email ? "error-field" : ""}
          />
          {errors.name && <div className="error">{errors.name}</div>}
          <input
            type="email"
            placeholder="Adres E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={errors.email ? "error-field" : ""}
          />
          {errors.email && <div className="error">{errors.email}</div>}
          <textarea
            cols="30"
            rows="10"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={errors.email ? "error-field" : ""}
          ></textarea>
          {errors.message && <div className="error">{errors.message}</div>}
          <button type="submit">Wyślij E-mail</button>
        </form>
        {submitSuccess && (
          <div className="submit-success">Mail został pomyślnie wysłany!</div>
        )}
      </div>
    </div>
  );
};

export default Contact;
