import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

function Confirm() {
  const { token } = useParams(); 

  useEffect(() => {
    async function confirmReservation() {
      try {
        const updatedData = { reserved: true };
        const { data, error } = await supabase
          .from("meetings")
          .update(updatedData)
          .eq("token", token)
          .single();
        if (error) throw error;
        console.log("Confirmed reservation: ", data);
      } catch (error) {
        console.error("Error confirming reservation: ", error);
      }
    }

    confirmReservation();
  }, [token]);

  return (
    <div>
      <h1 style={{ fontSize: "3rem" }}>Potwierdzenie rezerwacji</h1>
      <p style={{ fontSize: "2rem" }}>
        Twoja rezerwacja została potwierdzona. Dziękuję!
      </p>
    </div>
  );
}

export default Confirm;
