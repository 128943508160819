import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Reservation.sass";
import emailjs from "emailjs-com";
import { v4 as uuidv4 } from "uuid";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);
function AvailableTimes({ date, appointments, onReserve }) {
  const availableAppointments = appointments.filter(
    (appointment) =>
      new Date(appointment.date).toDateString() === date.toDateString()
  );

  return (
    <ul className="available-times">
      {availableAppointments.map((appointment) => {
        const timeWithoutSeconds = appointment.time.slice(0, 5);
        const dateTime = new Date(`${appointment.date}T${timeWithoutSeconds}`);
        return (
          <li key={appointment.id}>
            {dateTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            <button onClick={() => onReserve(appointment.id)}>Rezerwuj</button>
          </li>
        );
      })}
    </ul>
  );
}

function Reservation() {
  const [appointments, setAppointments] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showForm, setShowForm] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    getAppointments();
  }, []);

  async function getAppointments() {
    try {
      const { data, error } = await supabase
        .from("meetings")
        .select()
        .eq("reserved", false);
      if (error) throw error;
      console.log("Fetched appointments: ", data);
      setAppointments(data);
    } catch (error) {
      console.error("Error fetching appointments: ", error);
    }
  }

  async function reserveAppointment(id) {
    setSelectedAppointmentId(id);
    setShowForm(true);
  }

  async function submitForm(e) {
    e.preventDefault();
    try {
      const token = uuidv4();
      const updatedData = { reserved: false, token };
      const { data, error } = await supabase
        .from("meetings")
        .update(updatedData)
        .eq("id", selectedAppointmentId)
        .single();
      if (error) throw error;
      console.log("Reserved appointment: ", data);

      const date = new Date(selectedDate);
      const formattedDate = date.toLocaleDateString("pl-PL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });

      const confirmationLink = `http://localhost:3000/confirm/${token}`;

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_2_ID,
          {
            to_email: email,
            appointment_date: formattedDate,
            confirmation_link: confirmationLink,
          },
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setFormSubmitted(true); // Ustaw formSubmitted na true po pomyślnym wysłaniu formularza
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
      getAppointments();
    } catch (error) {
      console.error("Error reserving appointment: ", error);
    }
    setShowForm(false);
    setEmail("");
  }

  function tileClassName({ date, view }) {
    if (view === "month") {
      if (
        appointments.find(
          (appointment) =>
            new Date(appointment.date).toDateString() === date.toDateString()
        )
      ) {
        return "available";
      } else {
        return "unavailable";
      }
    }
  }

  function onDateChange(date) {
    setSelectedDate(date);
  }
  function tileDisabled({ date, view }) {
    if (view === "month") {
      return !appointments.find(
        (appointment) =>
          new Date(appointment.date).toDateString() === date.toDateString()
      );
    }
  }

  return (
    <div className="reservation-container">
      <h1 className="reservation-header">Rezerwacja spotkań</h1>
      <p>
        Wybierz termin, nastęnie potwierdź rezerwacje klikając w link otrzymany
        w mailu
      </p>
      <Calendar
        className="calendar"
        onChange={onDateChange}
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
      />
      <AvailableTimes
        className="available-times"
        date={selectedDate}
        appointments={appointments}
        onReserve={reserveAppointment}
      />
      {showForm && (
        <form onSubmit={submitForm}>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              title="Proszę wprowadzić poprawny adres e-mail."
              style={{ width: "100%" }}
            />
          </label>
          <button type="submit">Potwierdź</button>
          {formSubmitted && (
            <p>
              Formularz został pomyślnie wysłany! Sprawdź swoją skrzynkę e-mail,
              aby potwierdzić rezerwację.
            </p>
          )}
        </form>
      )}
    </div>
  );
}
export default Reservation;
