import React from "react";
import "./Aboutme.sass";

const Aboutme = () => {
  return (
    <section className="aboutme-container">
      <h1>O mnie</h1>
      <div className="img-container">
        <img className="sing1" src="../images/koncert1.jpg" alt="koncert1" />
        <img className="sing2" src="../images/koncert2.jpg" alt="koncert2" />
      </div>

      <div className="aboutme-content">
        <b>Adrianna Bąk</b> - 20 letnia wokalistka, obecnie studiująca kierunek
        Musical na Akademii Muzycznej im. Jana Ignacego Paderewskiego w
        Poznaniu. Związana ze Stargardzkim Centrum Kultury gdzie występuje na
        różnych koncertach, wydarzeniach kulturalnych oraz na wydarzeniach
        organizowanych przez Urząd Miejski miasta Stargard. Laureatka wielu
        konkursów i festiwali wokalnych. Stypendystka Prezydenta Miasta
        Stargard.
      </div>
    </section>
  );
};

export default Aboutme;
