import "./App.sass";
import Navbar from "./components/Navbar/Navbar.jsx";
import Hero from "./components/Hero/Hero.jsx";
import Aboutme from "./components/AboutMe/Aboutme.jsx";
import Video from "./components/Video/Video.jsx";
import Contact from "./components/Contact/Contact.jsx";
import Reservation from "./components/Reservation/Reservation.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Confirm from "./components/Reservation/Confirm.jsx";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const AnimationApp = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location);

  useEffect(() => {
    if (location !== prevLocation) {
      setPrevLocation(location);
    }
  }, [location, prevLocation]);

  return (
    <div className="container">
      <TransitionGroup>
        <CSSTransition
          key={prevLocation.key}
          classNames="fade"
          timeout={{ enter: 620, exit: 5 }}
        >
          <Routes location={prevLocation}>
            <Route path="/" element={<Hero />} />
            <Route path="about-me" element={<Aboutme />} />
            <Route path="video" element={<Video />} />
            <Route path="contact" element={<Contact />} />
            <Route path="reservation" element={<Reservation />} />
            <Route path="confirm/:token" element={<Confirm />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <AnimationApp />
      <Footer />
    </Router>
  );
};

export default App;
