import React from "react";
import "./Hero.sass";

const Hero = () => {
  return (
    <section className="hero-container">
      <div className="hero-content ">
        <h1>Adrianna Bąk</h1>
        <h2>Wokalistka/Aktorka Musicalowa</h2>
        <p>
          Cześć, nazywam się Adrianna!<br></br> Zapraszam do zapoznania się z
          moją ofertą.
        </p>
      </div>
      <img className="hero-img" src="../images/tlohero.jpg" alt="ja" />
    </section>
  );
};

export default Hero;
