import React, { useState } from "react";
import "./Navbar.sass";
import MobileNav from "./MobileNav/MobileNav.jsx";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />
      <nav className="nav-wrapper">
        <div className="nav-content">
          <Link to="/" className={location.pathname === "/" ? "disabled" : ""}>
            <img className="logo" src="/images/logo.png" alt="logo" />
          </Link>

          <ul>
            <li>
              <Link
                to="/"
                className={`menu-item ${
                  location.pathname === "/" ? "disabled" : ""
                }`}
              >
                Strona Główna
              </Link>
            </li>
            <li>
              <Link
                to="/about-me"
                className={`menu-item ${
                  location.pathname === "/about-me" ? "disabled" : ""
                }`}
              >
                O Mnie
              </Link>
            </li>
            <li>
              <Link
                to="/video"
                className={`menu-item ${
                  location.pathname === "/video" ? "disabled" : ""
                }`}
              >
                Wideo
              </Link>
            </li>
            <li>
              <Link
                to="/reservation"
                className={`menu-item ${
                  location.pathname === "/reservation" ? "disabled" : ""
                }`}
              >
                Rezerwacja
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`menu-item ${
                  location.pathname === "/contact" ? "disabled" : ""
                }`}
              >
                Kontakt
              </Link>
            </li>
          </ul>
          <button
            className={`menu-btn ${openMenu ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "1.8rem" }}
            >
              {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
