import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./MobileNav.sass";

const MobileNav = ({ isOpen, toggleMenu }) => {
  const location = useLocation();

  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <Link to="/" className={location.pathname === "/" ? "disabled" : ""}>
            <img className="logo" src="../images/logo.png" alt="logo" />
          </Link>
          <ul>
            <li>
              <Link
                to="/"
                className={`menu-item ${
                  location.pathname === "/" ? "disabled" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about-me"
                className={`menu-item ${
                  location.pathname === "/about-me" ? "disabled" : ""
                }`}
              >
                O Mnie
              </Link>
            </li>
            <li>
              <Link
                to="/video"
                className={`menu-item ${
                  location.pathname === "/video" ? "disabled" : ""
                }`}
              >
                Wideo
              </Link>
            </li>
            <li>
              <Link
                to="/reservation"
                className={`menu-item ${
                  location.pathname === "/reservation" ? "disabled" : ""
                }`}
              >
                Rezerwacja
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`menu-item ${
                  location.pathname === "/contact" ? "disabled" : ""
                }`}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
