import React from "react";
import "./Video.sass";
import { useMediaQuery } from "react-responsive";

const Video = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const videos = [
    "https://www.youtube.com/embed/kw7Z7Mv2Svg",
    "https://www.youtube.com/embed/VprizlCeqzU",
    "https://www.youtube.com/embed/22nLyklDy54",
    "https://www.youtube.com/embed/a6-dmw_FzLk?start=990&vq=hd1080&hl=pl-pl",
  ];
  return (
    <div className="video-container">
      <div
        style={{
          marginTop: "1rem",
          display: "grid",
          gridTemplateColumns: isSmallScreen
            ? "repeat(auto-fill, minmax(280px, 1fr))"
            : "repeat(auto-fill, minmax(560px, 1fr))",
          gap: "0.5rem",
        }}
      >
        <h2
          style={{
            gridColumn: "1 / -1",
            textAlign: "center",
            fontSize: "3.5rem",
          }}
          className="video-heading"
        >
          Nagrania z koncertów
        </h2>
        {videos.map((video, index) => (
          <div key={index}>
            <iframe
              width="100%"
              height="315"
              src={video}
              title={`Video ${index}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Video;
