import React from "react";
import "./Footer.sass";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-text">Sprawdź moje social media</div>
      <div className="footer-icons">
        <a
          href="https://www.instagram.com/adriannab64/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-instagram fa-white"></i>
        </a>
        <a
          href="https://www.youtube.com/@adriannabak920"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-youtube-play"></i>
        </a>
      </div>
    </div>
  );
}
